@import "utils/theme.scss";

.user-theme-violet{
	.sidebar_container{
		color:  #fff;
		background: #AD5CFF;

		.notes_filter{
			input{
				background: #6600CC;
				color: #fff;

				&::placeholder{
					color: #ccc;
				}
			}
		}
		.logo_container{
			.logo_name{
				span{
					color: $primary-dim;
				}
			}
		}
		.sidebar_pages_links{
			a{
				color:  #ededed;
				border-bottom:  1px solid #cccccc50;	
				svg{
					&, path{
						color: #fff;
						stroke: #fff;
					}
				}
				&.selected_note, &:hover, &.active{
					background: #6600CC;
					border-right-color: #000;
				}
			}
		}
	}
}