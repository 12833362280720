@import "utils/theme.scss";

.user-theme-aliceblue{
	.sidebar_container{
		color:  #fff;
		background: #9A9EA3;

		.notes_filter{
			input{
				background: #E3EAF2;
				color: #000;
			}
		}
		.logo_container{
			.logo_name{
				span{
					color: $primary-dim;
				}
			}
		}
		.sidebar_pages_links{
			a{
				color:  #000;
				border-bottom:  1px solid #cccccc50;	
				svg{
					&, path{
						color: #fff;
						stroke: #fff;
					}
				}
				&.selected_note, &:hover, &.active{
					background: #E3EAF2;
					border-right-color: #464646;
				}
			}
		}
	}
}