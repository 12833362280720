/**
 * prism.js Funky theme
 * Based on “Polyfilling the gaps” talk slides http://lea.verou.me/polyfilling-the-gaps/
 * @author Lea Verou
 */
 .cb-theme-funky{
	code[class*="language-"],
	pre[class*="language-"] {
		font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
		font-size: 1em;
		text-align: left;
		white-space: pre;
		word-spacing: normal;
		word-break: normal;
		word-wrap: normal;
		line-height: 1.5;

		-moz-tab-size: 4;
		-o-tab-size: 4;
		tab-size: 4;

		-webkit-hyphens: none;
		-moz-hyphens: none;
		-ms-hyphens: none;
		hyphens: none;
	}

	/* Code blocks */
	pre[class*="language-"] {
		padding: .4em .8em;
		margin: .5em 0;
		overflow: auto;
		background: url('data:image/svg+xml;charset=utf-8,<svg%20version%3D"1.1"%20xmlns%3D"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg"%20width%3D"100"%20height%3D"100"%20fill%3D"rgba(0%2C0%2C0%2C.2)">%0D%0A<polygon%20points%3D"0%2C50%2050%2C0%200%2C0"%20%2F>%0D%0A<polygon%20points%3D"0%2C100%2050%2C100%20100%2C50%20100%2C0"%20%2F>%0D%0A<%2Fsvg>');
		background-size: 1em 1em;
	}

	code[class*="language-"] {
		background: black;
		color: white;
		box-shadow: -.3em 0 0 .3em black, .3em 0 0 .3em black;
	}

	/* Inline code */
	:not(pre) > code[class*="language-"] {
		padding: .2em;
		border-radius: .3em;
		box-shadow: none;
		white-space: normal;
	}

	.token.comment,
	.token.prolog,
	.token.doctype,
	.token.cdata {
		color: #aaa;
	}

	.token.punctuation {
		color: #999;
	}

	.token.namespace {
		opacity: .7;
	}

	.token.property,
	.token.tag,
	.token.boolean,
	.token.number,
	.token.constant,
	.token.symbol {
		color: #0cf;
	}

	.token.selector,
	.token.attr-name,
	.token.string,
	.token.char,
	.token.builtin {
		color: yellow;
	}

	.token.operator,
	.token.entity,
	.token.url,
	.language-css .token.string,
	.token.variable,
	.token.inserted {
		color: yellowgreen;
	}

	.token.atrule,
	.token.attr-value,
	.token.keyword {
		color: deeppink;
	}

	.token.regex,
	.token.important {
		color: orange;
	}

	.token.important,
	.token.bold {
		font-weight: bold;
	}
	.token.italic {
		font-style: italic;
	}

	.token.entity {
		cursor: help;
	}

	.token.deleted {
		color: red;
	}

	/* Plugin styles: Diff Highlight */
	pre.diff-highlight.diff-highlight > code .token.deleted:not(.prefix),
	pre > code.diff-highlight.diff-highlight .token.deleted:not(.prefix) {
		background-color: rgba(255, 0, 0, .3);
		display: inline;
	}

	pre.diff-highlight.diff-highlight > code .token.inserted:not(.prefix),
	pre > code.diff-highlight.diff-highlight .token.inserted:not(.prefix) {
		background-color: rgba(0, 255, 128, .3);
		display: inline;
	}
 }