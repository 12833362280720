@import "utils/theme.scss";

.user-theme-aliceblue{
	#settings-popup-container-main{
		&, .hidding_layout{
			background: #00000060;
		}
		.poopup_main_content{
			background: #9A9EA3;
			h2{
				color: #333;
			}

			.close_cross_btn{
				svg{
					fill: red;
					background: #fff;
				}
			}

			.settings_popup_inner{
				.userinfo_form{
					input, label, select{
						color: #333;
					}

					input, select{
						border: 1px solid #ccc;
						background: #ccc;

						&:disabled{
							background-color: #aaa;
						}
					}

					button{
						background: #4E5153;
						color: #fff;
						&:hover{
							background: #fff;
							color: #000;
						}
					}
				}

				.recycle_bin{
					color: #333;
					&::-webkit-scrollbar-track {
						background: #ccc;
					}
					&::-webkit-scrollbar-thumb {
						background: #ffb500;
					}
					&::-webkit-scrollbar-thumb:hover{
						background: $primary;
					}

					.deleted_note{
						padding: 10px;
						border-bottom: 1px solid #ccc; 
						&:hover{
							background: #efefef;
						}
					}
				}
			}
		}
	}
}