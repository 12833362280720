@import "utils/theme.scss";
	
.share_popup_inner{
	// overflow: auto;
	// height: 100%;

	.loader_container{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 250px;
	}
	.share_note_form{
		input, label, select{
			display: block;
			width: 100%;
			
			span{
				width: 100%;
				display: block;
			}
		}
		input, select{
			height: 40px;
			padding: 10px;

			&:focus{
				outline: none;
			}
		}
		button{
			height: 40px;
		}
		input[name="share_url"]{
			user-select: all;
			cursor: grab;
		}
		.share_link{
			display: flex;
			margin-bottom: 20px;

			.copy_link_icon{
				width: 40px;
				height: 40px;
				cursor: pointer;

				&:hover{
					color: #ccc;
				}
			}
		}
		.bi_column{
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: end;
			grid-gap: 20px;
		}
	}

	
}