@import "utils/theme.scss";

@charset "utf-8";
.user-theme-light{
  .right_content .content_container{
    background: #e3e3e3;
  }
  .toastui-editor-dark .toastui-editor-code-block-language-list button{
    color: #555;
    background-color: #e3e3e3;

    &:hover{
      background-color: #aaa;
      color: #333
    }
  }
}