@import "utils/theme.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.admin_links_container{
  background-color: black;
}
.admin_links {
  width: 95vw;
  max-width: 1024px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  margin: 15px 0px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;

  .coupon_link{
    display: flex;
    grid-gap: 15px;
    width: 650px;
    justify-content: space-between;
  }
  
  .heading {
    margin-bottom: 10px;
  }
  
  .text {
    font-size: 25px;
  }
  
  .back_home {
    width: 80%;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 4px;
    font-size: 25px;
    color: #fff;
    font-weight: lighter;
    padding: 7px 5px;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 10px;
    letter-spacing: 2px;
    text-decoration: none;
  }
}


