@import "utils/theme.scss";

.user-theme-coffee{
	#settings-popup-container-main{
		&, .hidding_layout{
			background: #00000060;
		}
		.poopup_main_content{
			background: #483C26;
			h2{
				color: #fff;
			}

			.close_cross_btn{
				svg{
					fill: red;
					background: #fff;
				}
			}

			.settings_popup_inner{
				.userinfo_form{
					input, label, select{
						color: #fff;
					}

					input, select{
						border: 1px solid #ccc;
						color: #000;
						background-color: #8d754a;
						color: #fff;

						&:disabled{
							caret-color: #000;
							background-color: #c0a774;
						}
					}

					button{
						background: #af925c;
						color: #fff;
						&:hover{
							background: #fff;
							color: #2E2618;
						}
					}
				}

				.recycle_bin{
					color: #fff;
					&::-webkit-scrollbar-track {
						background: #ccc;
					}
					&::-webkit-scrollbar-thumb {
						background: #ffb500;
					}
					&::-webkit-scrollbar-thumb:hover{
						background: $primary;
					}

					.deleted_note{
						padding: 10px;
						border-bottom: 1px solid #ccc; 
						&:hover{
							background: #d8b779;
							color: #483C26;
						}
					}
				}
			}
		}
	}
}