@import "utils/theme.scss";

.user-theme-dark{
	#settings-popup-container-main{
		&, .hidding_layout{
			background: #e5e5e560;
		}
		.poopup_main_content{
			background: #4e4e4e;
			h2{
				color: #fff;
			}

			.close_cross_btn{
				svg{
					fill: red;
					background: #fff;
				}
			}

			.settings_popup_inner{
				.userinfo_form{
					input, label, select{
						color: #fff;
					}

					input, select{
						border: 1px solid #ccc;
						color: #333;
						background-color: #ccc;
						outline: none;

						&:disabled{
							background-color: #aaa;
						}
					}

					button{
						background: #7E7F82;
						color: #fff;
						&:hover{
							background: #fff;
							color: #7E7F82;
							border: 1px solid #7E7F82;
						}
					}
				}

				.recycle_bin{
					color: #A9A9A9;
					&::-webkit-scrollbar-track {
						background: #ccc;
					}
					&::-webkit-scrollbar-thumb {
						background: #ffb500;
					}
					&::-webkit-scrollbar-thumb:hover{
						background: $primary;
					}

					.deleted_note{
						padding: 10px;
						border-bottom: 1px solid #ccc; 
						&:hover{
							background: #efefef;
						}
					}
				}
			}
		}
	}
}