@import "utils/theme.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');


.not_found_container{
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, $primary-dim, $primary);
  height:  100vh;
}
.about_us {
  width: 95vw;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  margin: 15px 0px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.about_us .heading {
  margin-bottom: 10px;
}

.about_us .text {
  font-size: 25px;
}

.about_us .back_home {
  width: 80%;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 4px;
  font-size: 25px;
  color: #fff;
  font-weight: lighter;
  padding: 7px 5px;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 10px;
  letter-spacing: 2px;
  text-decoration: none;
}