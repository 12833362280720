/*!
 * TOAST UI Chart 4th Edition
 * @version 4.3.4 | Tue May 25 2021
 * @author NHN. FE Development Lab <dl_javascript@nhn.com>
 * @license MIT
 */
.toastui-chart-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

/* export menu */

.toastui-chart-export-menu {
  font-family: Arial, sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  user-select: none;
  box-sizing: border-box;
}

.toastui-chart-export-menu-title {
  height: 34px;
  width: 140px;
  margin: 0;
  padding: 10px;
  box-sizing: inherit;
}

.toastui-chart-export-menu-btn-wrapper {
  width: 100%;
  height: 76px;
}

.toastui-chart-export-menu-btn {
  width: 50%;
  height: 50%;
  border: none;
  padding: 12px 3px;
  float: left;
  color: inherit;
  font-family: inherit;
  background: transparent;
  cursor: pointer;
}

.toastui-chart-export-menu-btn:hover {
  font-weight: bold;
}

.toastui-chart-export-menu-btn:nth-child(3) {
  border-bottom-left-radius: inherit;
}

.toastui-chart-export-menu-btn:nth-child(4) {
  border-bottom-right-radius: inherit;
}

/* tooltip */
.toastui-chart-tooltip-container {
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  pointer-events: none;
}

.toastui-chart-tooltip {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 150px;
}

.toastui-chart-tooltip-category {
  padding: 8px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.toastui-chart-tooltip-series-wrapper {
  display: flex;
  padding: 13px 15px;
  flex-direction: column;
}

.toastui-chart-tooltip-series {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.toastui-chart-series-name {
  display: flex;
  align-items: center;
  /* for IE */
  line-height: 10px;
}

.toastui-chart-series-name .toastui-chart-icon,
.toastui-chart-tooltip-series .toastui-chart-icon {
  width: 10px;
  height: 10px;
  margin-right: 8px;
  /* for IE */
  display: inline-block;
}

.toastui-chart-series-name .toastui-chart-name {
  white-space: nowrap;
}

.toastui-chart-series-value {
  white-space: nowrap;
  margin-left: 10px;
}

.toastui-chart-tooltip-series-wrapper .toastui-chart-tooltip-title {
  padding: 8px 0 5px;
}

.toastui-chart-tooltip-series-wrapper .toastui-chart-tooltip-title:first-child {
  padding-top: 0;
}

